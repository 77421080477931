import React from 'react';

import { BoltIcon } from "@heroicons/react/24/outline";
import { Link } from 'gatsby';

const Layout = ({ children, title, className }) => {

    return (
        <div className="">
            <div className="sm:max-w-xl mx-auto p-5  border-b sm:border-x ">
                <div className='flex justify-between'>
                    <Link to="/">
                        <BoltIcon className="h-6 w-6 hover:fill-yellow-300 hover:cursor-pointer " />
                    </Link>
                    <div className='flex space-x-5'>
                        <Link to="/om" className="blog-sm-text hover:text-bloghalka-main">
                            om
                        </Link>
                        <Link to="/blogg" className="blog-sm-text hover:text-bloghalka-main">
                            blogg
                        </Link>

                    </div>
                </div>
            </div>

            <div className=" ">
                <div className={`sm:max-w-xl sm:border-x min-h-screen pb-32  mx-auto items-start ${className}`}>
                    {children}
                </div>
            </div>


        </div>
    );
};



export default Layout;